"use client"

import { useState } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from "next/link";

import ContentImage from 'components/display/ContentImage';
import Button from 'components/forms/Button';
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';

import Style from './DynamicHero.module.scss';


function DynamicHero({
    heading,
    subHeading,
    image,
    learnMoreBlock,
    contentTitle,
    contentDesc,
    ctaText,
    ctaUrl = "#",
    hasLogo,
    logoUrl,
    logoImage,
    logoPosition,
    responsiveHeroImage,
    mobileView
}) {

    const [learnMore, setLearnMore] = useState(Number(learnMoreBlock));

    const toggleLearMore = e => {
        e.preventDefault();
        setLearnMore(!learnMore);
    };  

    return (
        <>
             <Grid className={clsx(Style.heroMargin)}>
                {(contentTitle === null && contentDesc === null) && (
                    <GridColumn start={1} end={12} className={(learnMore ? Style.hideBlock : Style.showBlock)}>
                    
                        { heading && <h1 className={Style.heroTitle}>{heading}</h1> }
                        { subHeading && <div className={Style.heroSubtitle}>{subHeading}</div> }

                        {
                            (!mobileView && hasLogo === '1') ? (
                                <div className={Style.imageBackgroundLayer}>
                                    <Link href={logoUrl} title={heading}>
                                        {image && (
                                            <ContentImage 
                                                img={image?.[0]?.url ?? image}  
                                                className={Style.image} 
                                            />
                                        )}
                                        
                                        <div className={clsx(Style.logoAlign, (logoPosition === 'right') ? Style.logoRight : Style.logoLeft)}>
                                            <ContentImage 
                                                img={logoImage?.[0] ?? url}  
                                                className={Style.image} 
                                            />
                                        </div>
                                    </Link>
                                </div>

                            ):(

                                (ctaUrl) && (
                                    <Link href={ctaUrl} title="">
                                        {mobileView && (responsiveHeroImage.length !== 0) ? (
                                             <ContentImage 
                                                img={responsiveHeroImage?.[0] ?? logoUrl} 
                                                className={Style.image} 
                                            />
                                        ):(
                                            <>
                                                {image && (
                                                    <ContentImage 
                                                        img={image?.[0] ?? image} 
                                                        className={Style.image} 
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Link>
                                )
                            )}
                    </GridColumn>
                )}


                {(contentTitle && contentDesc) && (
                    <GridColumn start={1} end={12} >
                        <div  className={clsx(Style.hasContentSection, ((learnMore) ? Style.hideBlock : Style.showBlock))}>
                            { !mobileView && (
                                <div 
                                    className={Style.hasContentSectionImage} 
                                    style={{ backgroundImage: `url(${image?.[0]?.url})` }} 
                                /> 
                            )}
                        
                            <div className={Style.hasBottomContentSection}>
                                <h2 className={Style.contentTitle}> 
                                    {contentTitle} 
                                </h2>

                                { mobileView && image && (
                                    <ContentImage 
                                        img={image?.[0] ?? url}  
                                        className={Style.image} 
                                    /> 
                                )}

                                <div className={Style.contentDesc} dangerouslySetInnerHTML={{ __html: contentDesc }} />
                            
                                {(ctaText && ctaUrl) && (
                                    <Button color="ocean" size="md" type="link" href={ctaUrl}>
                                        {ctaText}
                                    </Button>
                                )}

                            </div>
                        </div>

                        {learnMoreBlock === "1" && (
                            <div className={clsx((learnMore) ? Style.learnMoreBtn : Style.hideMoreBtn)}>
                                <Button color="ocean" size="md" type="link"  onClick={(e)=>toggleLearMore(e)}>
                                    {(learnMore) ? "Learn More" : "X"}
                                </Button>
                            </div>
                        )}
                    </GridColumn>
                )}
            </Grid>
        </>
    )
}


DynamicHero.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    image: PropTypes.array
}

export default DynamicHero;