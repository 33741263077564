import Style from "./CollectionPod.module.scss"
import SmartLink from "components/navigation/SmartLink";
import Image from "next/image";
import { blurImage } from 'lib/Constants/blurImage';
import { cloudinaryCms2ImageLoader, cloudinaryCmsImageLoader } from "thirdparty/Cloudinary/cloudinaryLoader";
import clsx from "clsx";



function CollectionPod({
    size = 4,
    ctaTitle,
    ctaLink = "#",
    image = [],
    position,
    totalChildren,
    isLoaded,
    onLastChild = () => null
}){
    return (
        <SmartLink href={ctaLink} title={ctaTitle} className={clsx((size > 3) ? Style.linkBlockRow : Style.linkBlock3PerRow, "keen-slider__slide")}>
            <figure className={clsx(Style.block, !isLoaded && Style.unloaded)}>
                <Image
                    width={isLoaded ? image?.[0]?.width : 100}
                    height={isLoaded ? image?.[0]?.height : 350}
                    alt={ctaTitle + " figure image"}
                    placeholder="blur"
                    blurDataURL={blurImage}
                    className={clsx((size > 3) ? Style.img  : Style.img3PerRow)}
                    src={isLoaded ? image?.[0]?.filename : blurImage}
                    loader={isLoaded && (image?.[0]?.url.includes("/cms/cms") ?  cloudinaryCms2ImageLoader : cloudinaryCmsImageLoader)}
                    onLoad={() => {
                        if( position === totalChildren ){
                            onLastChild(true)
                        }
                    }}
                />

                <figcaption className={Style.title}>
                    {ctaTitle}
                </figcaption>
            </figure>
        </SmartLink>
    );
}


export default CollectionPod;