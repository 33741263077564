import PropTypes from 'prop-types';
import clsx from 'clsx';
import Style from '../Card/Card.module.scss';


/* Card Body Component
------------------------------------*/ 
function CardBody({ className, align, children }){
    return(
        <div 
            role="article"
            className={clsx(
                Style.text, 
                className, 
                align && Style[`align__${align}`],
            )}
        >
            {children}
        </div>
    )
}

CardBody.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    align: PropTypes.oneOf(["start", "center", "end", "left"])
};


export default CardBody;

