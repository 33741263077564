"use client"

import utils from 'styles/globals/utils.module.scss';
import Style from './ImageContentRow.module.scss'

import clsx from 'clsx';
import PropTypes from 'prop-types';
import RenderHeading from '../RenderHeading';
import NostoPlacement from 'thirdparty/Nosto/NostoPlacement';
import Image from 'next/image';
import Link from 'next/link';
import { blurImage } from 'lib/Constants/blurImage';
import { cloudinaryCmsImageLoader, cloudinaryCms2ImageLoader } from 'thirdparty/Cloudinary/cloudinaryLoader';
import { useWindowDimensions } from 'custom_hooks/useWindowDimensions';
import { useRef } from 'react';

/**
 * CMS Content Block
 * @returns Image and Text Components in a Row
 */

function ImageContentRow({ 
    backgroundColor = {
        label: 'ice50',
        class: 'ice50'
    }, 
    imagePlacement,
    ctaUrl, 
    text, 
    heading, 
    image, 
    headingType,
    recommendationId,
    istextcentered,
    paddingX,
    paddingY,
    marginY,
    fullWidthBackground,
    element,
    children,
    tradeStatus,
    textWidth,
    imageWidth,
    customer_group_id,
    isMobile
}){
    
    const noPadCondition = useRef(Number(textWidth) === 34);

    if (customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    }

    return(
        <section 
            className={clsx(
                Style.block,
                backgroundColor?.class && utils[`bg_${backgroundColor?.class}`],
                paddingY && utils[`py_${paddingY}`]
            )}>

            {fullWidthBackground && (
                <div className={clsx(
                    Style.fullBackground, 
                    backgroundColor?.class && utils[`bg_${backgroundColor?.class}`]
                )}/>
            )}


            <div className={clsx(
                Style.imageCntr,
                utils[`order_${imagePlacement === "start" ? 0 : 2 }`],
                isMobile ? 'w_10' : utils[`w_${imageWidth}`],
            )}>
                {ctaUrl ? (
                    <Link href={ctaUrl} title={heading}>
                        <Image
                            alt={heading + " image"} 
                            className={Style.image}
                            width={image?.[0]?.width ?? 100}
                            height={image?.[0]?.height ?? 100}
                            placeholder="blur"
                            blurDataURL={blurImage}
                            priority
                            src={image?.[0]?.filename ?? blurImage}
                            loader={image?.[0]?.url.includes("/cms/cms") ?  cloudinaryCms2ImageLoader : cloudinaryCmsImageLoader}
                        />
                    </Link>
                ):(
                    <Image
                        alt={heading} 
                        className={Style.image}
                        width={image?.[0]?.width ?? 100}
                        height={image?.[0]?.height ?? 100}
                        placeholder="blur"
                        blurDataURL={blurImage}
                        priority
                        src={image?.[0]?.filename ?? blurImage}
                        loader={image?.[0]?.url.includes("/cms/cms") ?  cloudinaryCms2ImageLoader : cloudinaryCmsImageLoader}
                    />
                )}
            </div>
            

            <article className={clsx(
                Style.contentWrapper,
                isMobile ? 'w_10' : utils[`w_${textWidth}`],
            )}>
                <div className={clsx( Style.content, noPadCondition.current && Style.noPadding, istextcentered && utils.text_center, paddingX && utils[`px_${paddingX}`] )}>
                    <RenderHeading 
                        headingType={headingType} 
                        element={element} 
                        className={Style.heading}
                    >
                        {heading}
                    </RenderHeading>

                    <div className={Style.textBlock} dangerouslySetInnerHTML={{ __html: text }}/>

                    {children}

                    {recommendationId && (
                        <div className={clsx(utils.w_100)}>
                            <NostoPlacement 
                                id={recommendationId} 
                                size={1}
                                showTitle={false}
                            />
                        </div>
                    )}
                </div>
            </article>
        </section>
    )
    
}


ImageContentRow.propTypes = {
    backgroundColor: PropTypes.shape({
        label: PropTypes.string,
        class: PropTypes.string
    })
}

export default ImageContentRow;