"use client"
/* eslint-disable react-hooks/exhaustive-deps */


import PropTypes from 'prop-types';
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import CollectionPod from 'components/blocks/CollectionRow/CollectionPod';
import PodSlider from 'components/product/PodSlider';

import Styles from './ExplorationRow.module.scss'
import utils from 'styles/globals/utils.module.scss';
import useCurrentSlide from 'components/product/PodSlider/hooks/useCurrentSlide';


function ExplorationRow({
    heading,
    icons = [],
    size,
    tradeStatus,
    customer_group_id
}) {
    const perViewSize = useMemo(() => parseInt(size), [ size ])
    const currentSlide = useCurrentSlide()
    const [ active, setActive ] = useState(0)

    const settings = {
        animationEnded(s) {
            currentSlide.setCurrent(s.track.details.rel)
        },
        initial: 0,
        loop: false,
        mode: 'snap',
        origin: 'center',
        slides: {
            perView: perViewSize,
            spacing: 0
        },
        breakpoints: {
            '(max-width: 1440px)': {
                slides: {
                    perView: perViewSize > 6 ? 6 : perViewSize,
                    spacing: 0
                }
            },
            '(max-width: 1280px)': {
                slides: {
                    perView: perViewSize > 5 ? 5 : perViewSize,
                    spacing: 0
                }
            },
            '(max-width: 850px)': {
                initial: 1,
                slides: {
                    perView: 3.5,
                    spacing: 20,
                    origin: 'center'
                },
            },
            '(max-width: 600px)': {
                slides: {
                    perView: 1.5,
                    origin: 'center',
                    spacing: 20

                },
            }
        }
    }

    const IconPods = useMemo(() => {
        return (
            icons.map((item, index) => {
                const isLoaded = index < currentSlide.loaded.length + (perViewSize === index + 1 ? perViewSize : (perViewSize - 1))

                return(
                    <CollectionPod
                        size={size}
                        key={item.linkTitle}
                        {...item}
                        ctaLink={item?.href?.url || '#'}
                        ctaTitle={item?.linkTitle}
                        isLoaded={isLoaded}
                    />
                )
            })
        )
    }, [ icons, perViewSize, currentSlide.loaded ])


    if (customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    }

    return (
        <Grid>
            {heading && (
                <GridColumn isRow start={1} end={11} rowStart={1} rowEnd={2}>
                    <h3 className={clsx(utils.fs_3, utils.mb_2, utils.text_center, utils.w_100, Styles.explorationRowTitle)}>
                        {heading}
                    </h3>
                </GridColumn>
            )}

            <GridColumn
                isRow start={1}
                end={11}
                rowStart={2}
                rowEnd={3}
                role="slider"
                aria-valuemin="0"
                aria-valuemax={size}
                aria-valuenow={active}
            >
                <Grid className={clsx(size <= 4 ? Styles.noArrows : '', Styles.block)}>
                    <PodSlider settings={settings} size={size}>
                        {IconPods}
                    </PodSlider>
                </Grid>
            </GridColumn>
        </Grid>
    )

}


ExplorationRow.propTypes = {
    heading: PropTypes.string,
    icons: PropTypes.array
}


export default ExplorationRow